import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './calender.css';
import ListingTable from '../../../../listing/listing';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { Eventdayyarrnotcreatelist } from './eventdayyarrnotcreatelist';
import { timeConverter } from "../../../../helper/helperFunctions";
import { conflictingSlotsList } from '../EventSummaryPage/eventSummaryReducer';
import EventSummary from '../EventSummaryPage/EventSummary';

const Eventsslotsummery = () => {
  console.log("first console");
  const stroreDispatch = useDispatch();
  const dispatch = useDispatch();
  const [loaderFlag, setloaderFlag] = useState(true);

  const [responseData, setresponseData] = React.useState([]);
  const [conflictingresponseData, setconflictingresponseData] = React.useState([]);
  const [last10conflictingslotsdata, setlast10conflictingslotsdata] = React.useState([]);
  const [responseDataSecondlist, setresponseDataSecondlist] = React.useState([]);
  const [responseDataThirdlist, setresponseDataThirdlist] = React.useState([]);
  const [responseDataBiolist, setResponseDataBiolist] = React.useState([]);
  const [responseDataGoogleCheckedList, setResponseDataGoogleCheckedList] = React.useState([]);
  const [responseDataGoogleUnCheckedList, setResponseDataGoogleUnCheckedList] = React.useState([]);
  const [responseDataConflictingPullFlagExitList, setResponseDataConflictingPullFlagExitList] = React.useState([]);
  const [responseDataConflictingPullFlagNotExitList, setResponseDataConflictingPullFlagNotExitList] = React.useState([]);





  var requestData = {
    source: '',
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      type: 'desc',
      field: '_id',
    },
    searchcondition: {},
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
    secret: 'JwX.(1QHj',
  };

  // useEffect(() => {
  //   console.log("in use effect call");
  //   setTimeout(() => {
  //     stroreDispatch(fetchEventsData(requestData));

  //   }, 300);
  // }, []);

  const tableData = {
    tableId: 'noslotcreationevents',
    tableTitle: 'Slot Not Created for Events List',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/no-slot-creation-events',
      tableCountEndpoint: "api/no-slot-creation-events-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['event_title', 'description'],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete_icon",
      //   title: "Delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle_on_icon",
      //   title: "Status Change",
      // }
    ],
    // buttons: [
    // {
    //   id: "deleteCM001",
    //   type: 'delete',
    //   label: 'Delete',
    //   name: 'delete',
    // },
    // {
    //   id: "editCM001",
    //   type: 'edit',
    //   label: 'Edit',
    //   name: 'edit',
    // },
    // {
    //   id: "viewCM001",
    //   type: 'view',
    //   label: 'View',
    //   name: 'view',
    // },
    // ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: "http://localhost:3000/dev/",
        endPoint: "api/listingusers",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "firstname"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          {
            id: 1,
            label: "Search By Status",
            field: "status",
            values: ["Active", "Inactive"],
            value: "",
          },
        ],
        textsearch: [
          { id: 2, label: "Search By phone ", field: "phone", value: "" }
        ],
        autoCompleteSearch: [
          {
            id: 5,
            label: "Search By Name",
            field: "fullname_s",
            values: "",
            multiple: false,
            serverSearchData: {
              base_url: "http://localhost:3000/dev/",
              endpoint: "api6/autocompletemanagername",
              reqBody: {
                "condition": {
                  "limit": 10,
                  "skip": 0
                },
                "sort": {
                  "type": "desc",
                  "field": "_id"
                },
                "searchcondition": {}
              },
            },
          }
        ],
      }
    }
  }
  const tableData2 = {
    tableId: 'eventarrnotcreationeventsListing',
    tableTitle: 'Event Array Not Creation Events Listing',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/eventarr-not-creation-events',
      tableCountEndpoint: "api/eventarr-not-creation-events-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['event_title', 'description'],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete_icon",
      //   title: "Delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle_on_icon",
      //   title: "Status Change",
      // }
    ],
    // buttons: [
    // {
    //   id: "deleteCM001",
    //   type: 'delete',
    //   label: 'Delete',
    //   name: 'delete',
    // },
    // {
    //   id: "editCM001",
    //   type: 'edit',
    //   label: 'Edit',
    //   name: 'edit',
    // },
    // {
    //   id: "viewCM001",
    //   type: 'view',
    //   label: 'View',
    //   name: 'view',
    // },
    // ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/eventarr-not-creation-events",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "firstname"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          {
            id: 1,
            label: "Search By Status",
            field: "status",
            values: ["Active", "Inactive"],
            value: "",
          },
        ],
        textsearch: [
          { id: 2, label: "Search By phone ", field: "phone", value: "" }
        ],
        autoCompleteSearch: [
          {
            id: 5,
            label: "Search By Name",
            field: "fullname_s",
            values: "",
            multiple: false,
            serverSearchData: {
              base_url: "http://localhost:3000/dev/",
              endpoint: "api6/autocompletemanagername",
              reqBody: {
                "condition": {
                  "limit": 10,
                  "skip": 0
                },
                "sort": {
                  "type": "desc",
                  "field": "_id"
                },
                "searchcondition": {}
              },
            },
          }
        ],
      }
    }
  }
  const tableData3 = {
    tableId: 'userslotsforsixmon',
    tableTitle: 'Google Events for six months List',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/userslotsforsixmon',
      tableCountEndpoint: "api/userslotsforsixmon-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['event_title', 'description'],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete_icon",
      //   title: "Delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle_on_icon",
      //   title: "Status Change",
      // }
    ],
    // buttons: [
    // {
    //   id: "deleteCM001",
    //   type: 'delete',
    //   label: 'Delete',
    //   name: 'delete',
    // },
    // {
    //   id: "editCM001",
    //   type: 'edit',
    //   label: 'Edit',
    //   name: 'edit',
    // },
    // {
    //   id: "viewCM001",
    //   type: 'view',
    //   label: 'View',
    //   name: 'view',
    // },
    // ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/userslotsforsixmon",
        tableCountEndpoint: "api/userslotsforsixmon-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          {
            id: 1,
            label: "Search By Status",
            field: "status",
            values: ["Active", "Inactive"],
            value: "",
          },
        ],
        textsearch: [
          { id: 2, label: "Search By User Name ", field: "username", value: "" },
          // { id: 2, label: "Search By User User Name ", field: "username", value: "" },
          { id: 2, label: "Search By Organizer Email ", field: "organizermail", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }

  const bioIntegrationListTable = {
    tableId: 'bioIntegrationList',
    tableTitle: 'Bio Integration List',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/bio-integration-list',
      tableCountEndpoint: "api/bio-integration-list-count",
      deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['event_title', 'description'],
    // paginationType: "stackPagination",
    customBtnHead: [
      // {
      //   id: "deleteMultiple",
      //   type: "delete_icon",
      //   title: "Delete",
      // },
      // {
      //   id: "editMultiple",
      //   type: "toggle_on_icon",
      //   title: "Status Change",
      // }
    ],
    // buttons: [
    // {
    //   id: "deleteCM001",
    //   type: 'delete',
    //   label: 'Delete',
    //   name: 'delete',
    // },
    // {
    //   id: "editCM001",
    //   type: 'edit',
    //   label: 'Edit',
    //   name: 'edit',
    // },
    // {
    //   id: "viewCM001",
    //   type: 'view',
    //   label: 'View',
    //   name: 'view',
    // },
    // ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/bio-integration-list",
        tableCountEndpoint: "api/bio-integration-list-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Joined On Start Date",
            enddatelabel: "Joined On End Date",
            submit: "Search",
            field: "createdon_datetime",
          },
        ],
        selectsearch: [
          {
            id: 1,
            label: "Search By Status",
            field: "status",
            values: ["Active", "Inactive"],
            value: "",
          },
        ],
        textsearch: [
          { id: 1, label: "Search By User Name ", field: "username", value: "" },
          { id: 2, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 3, label: "Search By Event Title ", field: "event_title", value: "" },
          { id: 4, label: "Search By Booking User Email ", field: "bookingUserEmail", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }
  const getResponseDataGoogleCheckedListTable = {
    tableId: 'getResponseDataGoogleCheckedList',
    tableTitle: 'Google Event Checked List',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/google-event-checked-list',
      tableCountEndpoint: "api/google-event-checked-list-count",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['username', 'useremail'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],

    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/google-event-checked-list",
        tableCountEndpoint: "api/google-event-checked-list-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "created",
          },
        ],
        selectsearch: [

        ],
        textsearch: [
          { id: 1, label: "Search By User Name ", field: "username", value: "" },
          { id: 2, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 3, label: "Search By Organizer Email ", field: "organizermail", value: "" },
          { id: 4, label: "Search By Summary ", field: "summary", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }
  const getResponseDataGoogleUnCheckedListTable = {
    tableId: 'getResponseDataGoogleUnCheckedList',
    tableTitle: 'Google Event Unchecked List',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/google-event-unchecked-list',
      tableCountEndpoint: "api/google-event-unchecked-list-count",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['username', 'useremail'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],

    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/google-event-unchecked-list",
        tableCountEndpoint: "api/google-event-unchecked-list-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "created",
          },
        ],
        selectsearch: [

        ],
        textsearch: [
          { id: 1, label: "Search By User Name ", field: "username", value: "" },
          { id: 2, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 3, label: "Search By Organizer Email ", field: "organizermail", value: "" },
          { id: 4, label: "Search By Summary ", field: "summary", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }

  const conflictingPullFlagExitListTable = {
    tableId: 'conflictingPullFlagExitListTable',
    tableTitle: 'Conflicting Pull Flag Exit List Table',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/conflicting-pull-flag-exit-list',
      tableCountEndpoint: "api/conflicting-pull-flag-exit-list-count",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['username', 'useremail'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],

    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/conflicting-pull-flag-exit-list",
        tableCountEndpoint: "api/conflicting-pull-flag-exit-list-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "created",
          },
        ],
        selectsearch: [

        ],
        textsearch: [
          { id: 1, label: "Search By User Name ", field: "username", value: "" },
          { id: 2, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 3, label: "Search By Organizer Email ", field: "organizermail", value: "" },
          { id: 4, label: "Search By Summary ", field: "summary", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }

  const conflictingSlots = {
    tableId: 'conflictingSlots',
    tableTitle: 'Conflicting Slots',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'googleapi/conflicting-slot-list',
      tableCountEndpoint: "googleapi/conflicting-slot-list",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['username', 'useremail'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],

    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/conflicting-pull-flag-exit-list",
        tableCountEndpoint: "api/conflicting-pull-flag-exit-list-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "created",
          },
        ],
        selectsearch: [

        ],
        textsearch: [
          { id: 1, label: "Search By User Name ", field: "username", value: "" },
          { id: 2, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 3, label: "Search By Organizer Email ", field: "organizermail", value: "" },
          { id: 4, label: "Search By Summary ", field: "summary", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }


  const conflictingPullFlagNotExitListTable = {
    tableId: 'conflictingPullFlaNotgExitListTable',
    tableTitle: 'Conflicting Pull Flag Exit List Table',
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: 'api/conflicting-pull-flag-not-exit-list',
      tableCountEndpoint: "api/conflicting-pull-flag-not-exit-list-count",
      // deleteSingleUserEndpoint: "delete-single-event",
    },
    reqBody: {
      source: '',
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: 'desc',
        field: '_id',
      },
      searchcondition: {},
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E',
      secret: 'JwX.(1QHj',
    },
    deleteSingleUserReqBody: {
      "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE"
    },
    rowsPerPageOptions: [5, 20, 30],
    sortFields: ['username', 'useremail'],
    // paginationType: "stackPagination",
    customBtnHead: [

    ],

    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: "center"
      },
    },
    searchBarData: {
      heading: "This is Search Bar",
      api: {
        url: process.env.REACT_APP_API_URL,
        endPoint: "api/pull-flag-not-exit-list",
        tableCountEndpoint: "api/pull-flag-not-exit-list-count",
        reqBody: {
          "source": "",
          "condition": {
            "limit": 10,
            "skip": 0
          },
          "sort": {
            "type": "asc",
            "field": "username"
          },
        },
      },
      searchSettings: {
        datesearch: [
          {
            id: 0,
            startdatelabel: "Start Date",
            enddatelabel: "End Date",
            submit: "Search",
            field: "created",
          },
        ],
        selectsearch: [

        ],
        textsearch: [
          { id: 1, label: "Search By User Name ", field: "username", value: "" },
          { id: 2, label: "Search By User Email ", field: "useremail", value: "" },
          { id: 3, label: "Search By Organizer Email ", field: "organizermail", value: "" },
          { id: 4, label: "Search By Summary ", field: "summary", value: "" },
        ],
        autoCompleteSearch: [
          // {
          //   id: 5,
          //   label: "Search By Name",
          //   field: "fullname_s",
          //   values: "",
          //   multiple: false,
          //   serverSearchData: {
          //     base_url: "http://localhost:3000/dev/",
          //     endpoint: "api6/autocompletemanagername",
          //     reqBody: {
          //       "condition": {
          //         "limit": 10,
          //         "skip": 0
          //       },
          //       "sort": {
          //         "type": "desc",
          //         "field": "_id"
          //       },
          //       "searchcondition": {}
          //     },
          //   },
          // }
        ],
      }
    }
  }

  const getconflictingpullslotData = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(tableData.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/conflictingeventsummery',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++cv+++++++', respdata)

    if (respdata.status == 'success') {
      console.log("][][]souresh", respdata.result);
      setconflictingresponseData(respdata.result.gcalcheckingavailableslotslotsdata);
      setlast10conflictingslotsdata(respdata.result.last10conflictingslotsdata);
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        // respdata.results.res,
      )
      console.log('setconflictingresponseData', conflictingresponseData)
    }
  }

  const getdata = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(tableData.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/no-slot-creation-events',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++cv+++++++', respdata)

    if (respdata.status == 'success') {
      setresponseData([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      console.log('setresponseData', setresponseData)
    }
  }
  const getdataforotherlist = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(tableData.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/eventarr-not-creation-events',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++cv+++++++', respdata)

    if (respdata.status == 'success') {
      setresponseDataSecondlist([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      // console.log('setresponseData', setresponseDataSecondlist)
    }
  }
  const getdataforThirdlist = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(tableData.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/userslotsforsixmon',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++ getdataforThirdlist +++++++', respdata)

    if (respdata.status == 'success') {
      setresponseDataThirdlist([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      // console.log('setresponseData', setresponseDataSecondlist)
    }
  }

  const getBioIntegrationList = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(bioIntegrationListTable.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/bio-integration-list',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++cv+++++++', respdata)

    if (respdata.status == 'success') {
      setResponseDataBiolist([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      console.log('setResponseDataBiolist', setResponseDataBiolist)
    }
  }

  const getResponseDataGoogleCheckedList = async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(getResponseDataGoogleCheckedListTable.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/google-event-checked-list',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++ getResponseDataGoogleCheckedList +++++++', respdata)

    if (respdata.status === 'success') {
      setResponseDataGoogleCheckedList([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      // console.log('setresponseData', setresponseDataSecondlist)
    }
  }

  const getResponseDataGoogleUnCheckedList = async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(getResponseDataGoogleCheckedListTable.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/google-event-unchecked-list',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++ getResponseDataGoogleCheckedList +++++++', respdata)

    if (respdata.status === 'success') {
      setResponseDataGoogleUnCheckedList([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      // console.log('setresponseData', setresponseDataSecondlist)
    }
  }

  const conflictingPullFlagExitList = async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(conflictingPullFlagExitListTable.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/conflicting-pull-flag-exit-list',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++ conflictingPullFlagExitList +++++++', respdata)

    if (respdata.status === 'success') {
      setResponseDataConflictingPullFlagExitList([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      // console.log('setresponseData', setresponseDataSecondlist)
    }
  }

  const conflictingPullFlagNotExitList = async () => {

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: JSON.stringify(conflictingPullFlagNotExitListTable.reqBody),
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + 'api/conflicting-pull-flag-not-exit-list',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++ conflictingPullFlagNotExitList +++++++', respdata)

    if (respdata.status === 'success') {
      setResponseDataConflictingPullFlagNotExitList([...respdata.results.res])
      setloaderFlag(false)
      console.log(
        '=================respdata.results.res===================',
        respdata.results.res,
      )
      // console.log('setresponseData', setresponseDataSecondlist)
    }
  }

  const deleteAllData = async () => {
    setloaderFlag(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-type': 'application/json' },
      body: {},
    }
    console.log('********Niladri**********', process.env.REACT_APP_GOOGLE_CALENDER_URL)
    // return;
    const response = await fetch(
      process.env.REACT_APP_GOOGLE_CALENDER_URL + 'api7/delete-all-from-collection',
      requestOptions,
    )
    // console.log("++++response+++++++",response);

    const respdata = await response.json()
    console.log('++++ conflictingPullFlagNotExitList +++++++', respdata)
    setloaderFlag(false);

    if (respdata.status === 'Success') {
      console.log('All recordes are deleted successfully !!');
      window.location.reload();
    } else {
      alert('Something went wrong !!');
    }
  };



  var modifyTableHeaders = [
    // { val: 'event_title', name: 'Event Title' },
    { val: 'description', name: ' Decsription' },
    { val: 'starttime', name: 'Start Time' },
    { val: 'endtime', name: 'End Time' },
    { val: 'startdate', name: 'Start Date' },
    { val: 'timespan', name: 'Time Span' },
    { val: 'timezone', name: 'Timezone' }
  ]
  var modifyTableHeaders2 = [
    // { val: 'event_title', name: 'Event Title' },
    // { val: 'event_title', name: 'Event Title' },
    // { val: 'description', name: 'Description' },
    { val: 'start_date', name: 'Start Date' },
    { val: 'end_date', name: 'End Date' },
    { val: 'start_time', name: 'Start Time' },
    { val: 'end_time', name: 'End Time' },
    // { val: 'timezone', name: 'Timezone' },
    { val: 'username', name: 'User Name' }
  ]
  var modifyTableHeaders3 = [
    { val: 'username', name: 'User Name' },
    { val: 'summary', name: 'Summary' },
    { val: 'organizermail', name: 'Connected Gmail' },
    { val: 'useremail', name: 'User Email' },
    { val: 'eventstarttimedate', name: 'Start Date' },
    { val: 'eventendtimedate', name: 'End Date' },
    { val: 'eventtimezone', name: 'Timezone' },
  ]

  var modifyTableHeadersBioList = [
    { val: 'username', name: 'User Name' },
    { val: 'useremail', name: 'User E-mail' },
    { val: 'bookingUserEmail', name: 'Booking User E-mail' },
    { val: 'event_title', name: 'Event Title' },
    { val: 'start_time', name: 'Start Time' },
    { val: 'end_time', name: 'End Time' },
    { val: 'updatedat', name: 'Updated Date' }
    // { val: '_id', name: 'ID' }
  ]
  var modifyTableHeadersGoogleEventList = [
    { val: 'username', name: 'User Name' },
    { val: 'useremail', name: 'User E-mail' },
    { val: 'organizermail', name: 'Organizer E-mail' },
    { val: 'summary', name: 'Summary' },
    { val: 'lastgcalchecktime', name: 'Calendar Checked' },
    // { val: 'end_time', name: 'End Time' },
    { val: 'updatedat', name: 'Updated Date' }
    // { val: '_id', name: 'ID' }
  ]
  var modifyTableHeadersConflictingSlots = [
    { val: 'username', name: 'User Name' },
    { val: 'bookingUserEmail', name: 'Connected Gmail' },
    { val: 'useremail', name: 'User Email' },
    { val: "event_title", name: "Conflicted Event Title" },
    { val: 'summary', name: 'G-Cal Summary' },
    { val: 'startdate', name: 'Start Date' },
    { val: 'gap_deletion', name: 'Deletion Gap (Minutes)' },
    { val: 'slot', name: 'Slot' },
    // { val: 'updatedatlastgcalchecktime', name: 'Calendar Checked Date' },
    // { val: 'end_time', name: 'End Time' },
    // { val: 'updatedat', name: 'Updated Date' }
    // { val: '_id', name: 'ID' }
  ]

  // useEffect(() => {
  //   // if (responseData.length > 0) {
  //     console.log("useeffect")

  //     stroreDispatch(setTableData({ dataset: testdata,tableId: 'calendarAvailabilityTable'}));
  //   // }

  // }, [responseData])

  // useEffect(() => {
  //   dispatch(conflictingSlotsList())
  //   getconflictingpullslotData()

  //   getdata()
  // }, [])
  // useEffect(() => {
  //   getdataforotherlist()

  // }, [])
  // useEffect(() => {
  //   getdataforThirdlist()
  // }, [])
  // useEffect(() => {
  //   getBioIntegrationList();
  // }, [])

  // useEffect(() => {
  //   getResponseDataGoogleCheckedList()
  // }, [])
  // useEffect(() => {
  //   getResponseDataGoogleUnCheckedList();
  // }, [])
  // useEffect(() => {
  //   conflictingPullFlagExitList();
  // }, [])
  // useEffect(() => {
  //   conflictingPullFlagNotExitList();
  // }, [])

  // store.subscribe(() => {
  //   const storeData = store.getState();
  //   console.log("storeDatastoreData", storeData)
  //   if (storeData.calenderEventsStore.eventsDataSet != undefined && storeData.calenderEventsStore.eventsDataSet.length > 0 && storeData.calenderEventsStore.loading == false) {
  //     setresponseData(storeData.calenderEventsStore.eventsDataSet);


  //   }
  // })





  return (
    <>
      {/* {/ Meta Service Start Here /} */}

      {/* {/ Meta Service End Here /} */}
      <div className="mainContentWrapper form">
        <div className="ContentWrapper">
          <div className="Formdiv">
            {/* {/ Login Form Start Here /} */}
            <div className="FormBlock">
              <div className="buttonsetToolbar">
                <Button variant="contained">
                  <Link to="/calendar-management-createEvent">
                    Create New Availability
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link to="/calendar-management-available-slots">
                    Availability Slot
                  </Link>
                </Button>
                <Button
                  href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar?url=https://calendar.betoparedes.com/"
                  variant="contained"
                >
                  Update Google Account
                </Button>
              </div>

              <EventSummary />

              {/* {loaderFlag == true ? (
                <Box sx={{ width: "100%", mt: "10px" }}>
                  <LinearProgress sx={{ height: "5px" }} />
                </Box>
              ) : (
                ""
              )} */}

              {/* {/ Lists Section Starts /} */}
              {/* <div className="reactTableWrapper">
                <div className="reactTable">
                  <h1>Last 10 Conflict Calculation Execution Time :</h1>

                  {conflictingresponseData != null && conflictingresponseData.length > 0 ? (

                    conflictingresponseData.map((data, idx) => {
                      return <p> {timeConverter({ time: new Date(data.lastgcalchecktime), format: "L", timezone: "Asia/Kolkata" })} ,{ }
                        {timeConverter({ time: new Date(data.lastgcalchecktime), format: "h:mm A", timezone: "Asia/Kolkata" })}
                        <br></br>{data.organisermail}
                        <br></br>{data.summary}
                      </p>
                    })
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>

                <div className="reactTable">
                  <h1>Last 10 Conflict delete Execution Time :</h1>

                  {last10conflictingslotsdata != null && last10conflictingslotsdata.length > 0 ? (

                    last10conflictingslotsdata.map((data, idx) => {
                      return <p> {timeConverter({ time: new Date(data.pullconflictslotDateTime), format: "L", timezone: "Asia/Kolkata" })} ,{ }
                        {timeConverter({ time: new Date(data.pullconflictslotDateTime), format: "h:mm A", timezone: "Asia/Kolkata" })}
                        <br></br>{data.bookingUserEmail}
                        <br></br>{data.slot}
                        <br></br>{data.startdate}
                      </p>

                    })
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>

                <div className="reactTable">
                  <p>Slot Not Created for Events List</p>
                  {responseData ? (
                    <ListingTable
                      tableData={tableData}
                      dataset={responseData}
                      modifyHeaders={modifyTableHeaders}
                    />
                  ) : (
                    <p>Something Went Wrong! Please Reload</p>
                  )}
                </div>

                <div className="reactTable">
                  {responseDataSecondlist ? (
                    <Eventdayyarrnotcreatelist
                      tableData={tableData2}
                      dataset={responseDataSecondlist}
                      modifyHeaders={modifyTableHeaders2}
                    />
                  ) : (
                    <p>Something Went Wrong! Please Reload</p>
                  )}
                </div>

                <div className="reactTable">
                  {responseDataThirdlist ? (
                    <ListingTable
                      tableData={tableData3}
                      dataset={responseDataThirdlist}
                      modifyHeaders={modifyTableHeaders3}
                    />
                  ) : (
                    <p>Something Went Wrong! Please Reload</p>
                  )}
                </div>
                <div className="reactTable">
                  <p>Bio Integration List</p>
                  {responseDataBiolist && responseDataBiolist.length > 0 ? (
                    <ListingTable
                      tableData={bioIntegrationListTable}
                      dataset={responseDataBiolist}
                      modifyHeaders={modifyTableHeadersBioList}
                    />
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>
                <div className="reactTable">
                  <p>Google Event Checked List</p>
                  {responseDataGoogleCheckedList && responseDataGoogleCheckedList.length > 0 ? (
                    <ListingTable
                      tableData={getResponseDataGoogleCheckedListTable}
                      dataset={responseDataGoogleCheckedList}
                      modifyHeaders={modifyTableHeadersGoogleEventList}
                    />
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>
                <div className="reactTable">
                  <p>Google Event Unchecked List</p>
                  {responseDataGoogleUnCheckedList && responseDataGoogleUnCheckedList.length > 0 ? (
                    <ListingTable
                      tableData={getResponseDataGoogleUnCheckedListTable}
                      dataset={responseDataGoogleUnCheckedList}
                      modifyHeaders={modifyTableHeadersGoogleEventList}
                    />
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>
                <div className="reactTable">
                  <p>Calendar Deleted Conflicting List (Events Slots Automatically Deleted By System)</p>
                  {responseDataConflictingPullFlagExitList && responseDataConflictingPullFlagExitList.length > 0 ? (
                    <ListingTable
                      tableData={conflictingPullFlagExitListTable}
                      dataset={responseDataConflictingPullFlagExitList}
                      modifyHeaders={modifyTableHeadersConflictingPullFlagListTable}
                    />
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>
                <hr />
                <div className="reactTable">
                  {conflictingSlotsData ? (
                    <ListingTable
                      tableData={conflictingSlots}
                      dataset={conflictingSlotsData}
                      modifyHeaders={modifyTableHeadersConflictingSlots}
                    />
                  ) : (
                    <p>No Records Found</p>
                  )}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Eventsslotsummery;


