import {
  Alert,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Modal,
  Snackbar,
} from "@mui/material";
import MetaTags from "react-meta-tags";
import React, { useEffect, useRef, useState } from "react";
import "./calender.css";
import ListingTable from "../../../../listing/listing";
import { Link, useNavigate } from "react-router-dom";
import store from "../../../../store";
import { fetchEventsData } from "./calendarReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCustomButtonClicked,
  resetReloadTableFlag,
  resetRowActionBtnClicked,
  setReloadTableFlag,
  setTableData,
} from "../../../../listing/listReducer";
import { Listevent } from "./listevent";
import { Cookies } from "react-cookie";
import CloseIcon from "@mui/icons-material/Close";
import { ModalBox } from "../../../../listing/ModalBox";
import { TableSkeleton } from "../../../Common/TableSkeleton";

const EventListing = () => {
  const cookies = new Cookies();
  const getcookies = cookies.getAll();
  console.log("first console");
  const stroreDispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("cookies in layout==>", getcookies.userInfo._id);
  const [loaderFlag, setloaderFlag] = useState(true);
  // const [eventListingData, seteventListingData] = useState([]);
  const [responseData, setresponseData] = React.useState(null);
  const [norecordsFlag, setnorecordsFlag] = React.useState(false);
  const [useridsetdata, setuseridsetdata] = React.useState(
    getcookies.userInfo?._id
  );
  const [multiSelectedItem, setMultiSelectedItem] = useState([]);
  const [snackBarMsg, setSnackBarMsg] = useState("");
  const [snakbaropen, setsnakbaropen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openActionModal, setOpenActionModal] = React.useState(false);

  const edit = useSelector((state) =>
    Boolean(
      state.tableSlice?.customButtonClicked?.calendarAvailabilityTable?.edit
    )
  );
  const rowData = useSelector(
    (state) => state.tableSlice?.tempRowData?.calendarAvailabilityTable
  );

  useEffect(() => {
    console.log("rowData===================>", rowData);
    if (edit && rowData) {
      navigate(`/calendar-management-edit-event/${rowData._id}`);
    }
  }, [edit, rowData]);

  var requestData = {
    source: "",
    condition: {
      limit: 5,
      skip: 0,
    },
    sort: {
      type: "desc",
      field: "_id",
    },
    searchcondition: { userid: useridsetdata },
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
    secret: "JwX.(1QHj",
  };

  // useEffect(() => {
  //   console.log("in use effect call");
  //   setTimeout(() => {
  //     stroreDispatch(fetchEventsData(requestData));

  //   }, 300);
  // }, []);

  const tableData = {
    tableId: "calendarAvailabilityTable",
    tableTitle: "Calendar Availability Listing",
    showReload: true,
    showFilter: true,
    api: {
      url: process.env.REACT_APP_API_URL,
      deleteUrl: process.env.REACT_APP_BASE_URL,
      endPoint: "api/availability-list",
      tableCountEndpoint: "api/availability-list-count",
      deleteSingleUserEndpoint: "delete-event",
    },
    reqBody: {
      source: "",
      condition: {
        limit: 5,
        skip: 0,
      },
      sort: {
        type: "desc",
        field: "_id",
      },
      searchcondition: { userid: useridsetdata },
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NDg1NDAyNTgsImlhdCI6MTY0ODQ1Mzg1OH0.QGs0yduTqQSjHj1mEmmuu4Ivb1IO5sgcnTCIH_DOu1E",
      secret: "JwX.(1QHj",
    },
    deleteSingleUserReqBody: {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
    },
    rowsPerPageOptions: [5, 20, 30, 50, 100],
    sortFields: ["event_title", "event_type_new", "timespan"],
    // paginationType: "stackPagination",
    customBtnHead: [
      {
        id: "deleteMultiple",
        type: "delete",
        title: "Delete",
        name: "delete",
      },
    ],
    buttons: [
      {
        id: "delete",
        label: "Delete",
        type: "api",
        category: "modal",
        icon_type: "delete",
        className: "delete_data",
        modalTitle: "<p>Are you sure you want to delete these records?</p>",
        modalHeader: "<p>Alert !!</p>",
        modalClassName: "delete_modal",
        btn_label: ["Yes", "No"],
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "googleapi/delete-event",
          body: {
            source: "events",
            secret: "AZ|lepL`",
            token:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTIxNjM3MDUsImlhdCI6MTY1MjA3NzMwNX0.3kB4iRr5JbmV81Dl5gKA7fiTaMEEp0xbsWoO-wA0XUo",
          },
        },
      },
      {
        id: "edit",
        type: "other",
        label: "Edit",
        name: "edit_icon",
      },
      {
        id: "viewApi",
        label: "Preview Event",
        type: "api",
        category: "view",
        icon_type: "preview",
        className: "preview",
        modalTitle: "Event Details",
        modalHeader: "",
        modalClassName: "view_modal event_view_modal",
        api_data: {
          base_url: process.env.REACT_APP_API_URL,
          endpoint: "api/availability-list",
          body: {
            source: "",
            condition: { limit: 1, skip: 0 },
            sort: { type: "asc", field: "fullname" },
            searchcondition: {},
          },
        },
        headers: [
          { val: "event_title", label: "Event Title" },
          { val: "username", label: "User Name" },
          { val: "useremail", label: "User Email" },
          { val: "product_id", label: "Product" },
          { val: "description", label: "Description" },
          { val: "timespan", label: "Timespan" },
          {
            val: "start_date",
            label: "Event Start Date",
            type: "datetime",
            format: "MM/DD/YYYY",
          },
          {
            val: "end_date",
            label: "Event End Date",
            type: "datetime",
            format: "MM/DD/YYYY",
          },
          {
            val: "created_at",
            label: "Created On",
            type: "datetime",
            format: "MM/DD/YYYY hh:mm:ss A",
          },
        ],
      },
    ],
    deleteModal: {
      modalClassName: "deleteModal modalblock",
      modalStyle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        textAlign: "center",
      },
    },
    searchData: {
      // heading: "Search Event",
      type: "drawer",
      condition_type: "$or",
      formData: {
        id: "searchForm",
        // formWrapperClass: "formWrapperClass",// Can be undefined default will be formCls
        // formInlineCLass: "searchForm", // Can be undefined default will be formInline
        formButtonClass: "submitbtnsection",
        // submitBtnName: "Search",
        // submitBtnEndIcon: "search",
        // resetBtnName: "Reset",
        // resetBtnEndIcon: "replay",
        resetForm: true,
        formAdditionalSubmissionData: {},
        fields: [
          {
            id: 0,
            label: "Search By Event Title",
            name: "event_title",
            type: "text",
            className: "formGroup countDiv__column--col6",
            sx: { m: 1, minWidth: 300 },
            payloadFormat: {
              key: "event_title",
              param: "$regex",
              options: "i",
            },
          },
          {
            id: 2,
            label: "Search By Event Type",
            name: "event_type",
            className: "formGroup countDiv__column--col6",
            type: "select",
            multiple: true,
            values: [
              {
                val: 1,
                name: "Discovery Call with Beto Paredes for BioEnergetics",
              },
              {
                val: 2,
                name: "PECE portal discovery webcast with Michael White",
              },
              { val: 3, name: "BioEnergetics Integration Meeting" },
              { val: 4, name: "PECE™ Integration Meeting" },
              {
                val: 5,
                name: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
              },
            ],
            payloadFormat: { key: "event_type", param: "$in" },
          },
          {
            id: 3,
            label: "Search by Product",
            name: "product_id",
            className: "formGroup countDiv__column--col6",
            multiple: true,
            type: "select",
            values: [
              {
                val: "PECE platform and TM-Flow",
                name: "PECE platform and TM-Flow",
              },
              {
                val: "BioEnergetics platform with neoGEN Series",
                name: "BioEnergetics platform with neoGEN Series",
              },
              {
                val: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
                name: "Meeting with Beto Paredes to discuss the Aspire Partners tax and expense reduction programs",
              },
            ],
            payloadFormat: { key: "product_id", param: "$in" },
          },
          {
            id: 3,
            label: "Search by Timespan",
            name: "timespan",
            className: "formGroup countDiv__column--col6",
            multiple: true,
            type: "select",
            values: [
              { val: 15, name: "15 Min" },
              { val: 30, name: "30 Min" },
              { val: 45, name: "45 Min" },
              { val: 60, name: "60 Min" },
              { val: 90, name: "90 Min" },
              { val: 120, name: "120 Min" },
            ],
            payloadFormat: { key: "timespan", param: "$in" },
          },
          {
            id: 8,
            label: "Search by Start Date From",
            name: "start_date_unix",
            type: "datePicker",
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: "formGroup countDiv__column--col6",
            payloadFormat: { key: "start_date_unix", param: "$gte" },
          },
          {
            id: 90,
            label: "Search by Start Date To",
            name: "start_date_unix_to",
            type: "datePicker",
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: "formGroup countDiv__column--col6",
            payloadFormat: { key: "start_date_unix", param: "$lte" },
          },
          {
            id: 8,
            label: "Search by End Date From",
            name: "end_date_unix",
            type: "datePicker",
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: "formGroup countDiv__column--col6",
            payloadFormat: { key: "end_date_unix", param: "$gte" },
          },
          {
            id: 8,
            label: "Search by End Date To",
            name: "end_date_unix_to",
            type: "datePicker",
            dateFormat: "dd/MM/yyyy", //////////////////////////// MM should be capital //////////////////
            className: "formGroup countDiv__column--col6",
            payloadFormat: { key: "end_date_unix", param: "$lte" },
          },
        ],
      },
    },
  };
  const getdata = async () => {
    console.log("useridsetdata++++++++++", useridsetdata);
    tableData.reqBody.searchcondition.userid = useridsetdata;
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(tableData.reqBody),
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "api/availability-list",
      requestOptions
    );
    // console.log("++++response+++++++",response);

    const respdata = await response.json();
    console.log("++++cv+++++++", respdata);

    if (respdata.status == "success") {
      setresponseData([...respdata.results.res]);
      console.log(
        "=================respdata.results.res===================",
        respdata.results.res
      );
      setloaderFlag(false);

      if (responseData?.length == 0) {
        setnorecordsFlag(true);
      }
      console.log("setresponseData", setresponseData);
    } else {
      setsnakbaropen(true);
      snackBarMsg("Something Wrong!");
    }
  };
  var modifyTableHeaders = [
    { val: "event_title", name: "Event Title" },
    { val: "schedule", name: "Schedule" },
    { val: "event_type_new", name: "Event Type" },
    {
      val: "start_date",
      name: "Start Date",
      type: "datetime",
      format: "MM-DD-YYYY",
    },
    {
      val: "end_date",
      name: "End Date",
      type: "datetime",
      format: "MM-DD-YYYY",
    },
    { val: "timespan", name: "Timespan (Minutes)" },
    // { val: 'created_at', name: 'Created by' },
    { val: "product_id", name: "Product" },
    // { val: 'phone', name: 'phone' }
  ];

  // useEffect(() => {
  //   // if (responseData.length > 0) {
  //     console.log("useeffect")

  //     stroreDispatch(setTableData({ dataset: testdata,tableId: 'calendarAvailabilityTable'}));
  //   // }

  // }, [responseData])
  useEffect(() => {
    getdata();
  }, []);

  store.subscribe(() => {
    const storeData = store.getState();
    console.log("storeDatastoreData", storeData);
    if (
      storeData.tableSlice.customButtonClicked.calendarAvailabilityTable &&
      storeData.tableSlice.customButtonClicked.calendarAvailabilityTable
        .deleteMultiple
    ) {
      if (
        storeData.tableSlice.multipleSelectionList.calendarAvailabilityTable &&
        storeData.tableSlice.multipleSelectionList.calendarAvailabilityTable
          .length > 0
      ) {
        setMultiSelectedItem(
          storeData.tableSlice.multipleSelectionList.calendarAvailabilityTable
        );
        setOpenModal(true);
      }
    }
    if (
      storeData.tableSlice.customButtonClicked.calendarAvailabilityTable &&
      storeData.tableSlice.customButtonClicked.calendarAvailabilityTable.view
    ) {
      setOpenActionModal(true);
      viewActionModal.current.data =
        storeData.tableSlice.tempViewData.calendarAvailabilityTable;
    }
    // if (storeData.calenderEventsStore.eventsDataSet != undefined && storeData.calenderEventsStore.eventsDataSet.length > 0 && storeData.calenderEventsStore.loading == false) {
    //   setresponseData(storeData.calenderEventsStore.eventsDataSet);
    // }
  });

  useEffect(() => {
    if (multiSelectedItem.length > 0) {
      console.log(multiSelectedItem);
      // multiDeleteItem();
    }
  }, [multiSelectedItem]);

  const multiDeleteItem = async () => {
    // setsnakbaropen(true);
    // setSnackBarMsg('Delete Successfully');
    // stroreDispatch(clearCustomButtonClicked({ tableId: "calendarAvailabilityTable", buttonId: "deleteMultiple" }));
    // getdata();
    // stroreDispatch(setReloadTableFlag({ tableId: "calendarAvailabilityTable" }))
    // return
    let itemIds = [];
    multiSelectedItem.map((x) => itemIds.push(x._id));
    const reqBody = {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmb28iOiJiYXIiLCJleHAiOjE2NTM3MTM5ODEsImlhdCI6MTY1MzYyNzU4MX0.QT_QnUUm_0niY3UvVIIZH4baUUKs5I4ZAORzuqRWwWE",
      ids: itemIds,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(reqBody),
    };
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "delete-multiple-event",
      requestOptions
    );
    const respdata = await response.json();
    console.log("response multi delete", respdata);
    if (respdata.status === "success") {
      // console.log(
      //   '=================respdata.results.res===================',
      //   respdata.results.data,
      // )
      setloaderFlag(false);
      setsnakbaropen(true);
      setSnackBarMsg("Deleted Successfully");
      stroreDispatch(
        clearCustomButtonClicked({
          tableId: "calendarAvailabilityTable",
          buttonId: "deleteMultiple",
        })
      );
      stroreDispatch(
        setReloadTableFlag({ tableId: "calendarAvailabilityTable" })
      );
      getdata();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (snakbaropen === true) {
      setTimeout(() => {
        setsnakbaropen(false);
        setSnackBarMsg("");
        stroreDispatch(
          resetReloadTableFlag({ tableId: "calendarAvailabilityTable" })
        );
      }, 6000);
    }
  }, [snakbaropen]);
  const onHandleCloseModal = () => {
    setOpenModal(false);
  };
  const handleConfirmDelete = async (confirm) => {
    console.log("confirm", confirm);

    if (confirm) {
      setloaderFlag(true);
      multiDeleteItem();
    } else {
      stroreDispatch(
        clearCustomButtonClicked({
          tableId: "calendarAvailabilityTable",
          buttonId: "deleteMultiple",
        })
      );
      stroreDispatch(
        setReloadTableFlag({ tableId: "calendarAvailabilityTable" })
      );
      getdata();
    }

    setOpenModal(false);
  };
  /////////////////////////// Action Modal //////////////////////////////
  const viewActionModal = useRef({
    id: "modalViewDetails",
    className: "modal1 custommodal modalblock",
    style: {},
    heading: "Details",
    labels: {
      event_title: " Event Title",
      username: " User Name",
      useremail: " User Email",
      product_id: " Product",
      description: " Description",
    },
    data: null,
  });
  const closeModal = () => {
    setOpenActionModal(false);
    stroreDispatch(
      clearCustomButtonClicked({
        tableId: "calendarAvailabilityTable",
        buttonId: "view",
      })
    );
    stroreDispatch(
      resetRowActionBtnClicked({
        tableId: "calendarAvailabilityTable",
        buttonId: "view",
      })
    );
  };

  return (
    <>
      {/* {/ Meta Service Start Here /} */}

      {/* {/ Meta Service End Here /} */}
      <div className="mainContentWrapper form">
        <div className="ContentWrapper">
          <div className="Formdiv">
            {/* {/ Login Form Start Here /} */}
            <div className="FormBlock">
              <div className="buttonsetToolbar">
                <Button variant="contained">
                  <Link to="/calendar-management-createEvent">
                    Create New Availability
                  </Link>
                </Button>
                <Button variant="contained">
                  <Link to="/calendar-management-available-slots">
                    Availability Slot
                  </Link>
                </Button>
                <Button
                  href="https://d0fw1rgejc.execute-api.us-east-2.amazonaws.com/production/sync-calendar?url=https://calendar.betoparedes.com/"
                  variant="contained"
                >
                  Update Google Account 
                </Button> 
                <Button variant="contained">
                  <Link to="/calendar-management-booked-slots">
                    Booked Slot
                  </Link>
                </Button>
              </div>
              {loaderFlag == true ? (
                <Box sx={{ width: "100%", mt: "10px" }}>
                  <LinearProgress sx={{ height: "5px" }} />
                </Box>
              ) : (
                ""
              )}
              {/* {/ Lists Section Starts /} */}
              <div className="reactTableWrapper">
                <div className="reactTable">
                  {responseData ? (
                    <ListingTable
                      tableData={tableData}
                      dataset={responseData}
                      modifyHeaders={modifyTableHeaders}
                    />
                  ) : (
                    <TableSkeleton count={5} />
                  )}
                  {/* {norecordsFlag == true && <p>No Records Found</p>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className={tableData.deleteModal.modalClassName}
        open={openModal}
        onClose={onHandleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={
            tableData.deleteModal.modalStyle !== undefined
              ? tableData.deleteModal.modalStyle
              : {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 400,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                  textAlign: "center",
                }
          }
        >
          {/* Close Button */}
          <Button
            type="button"
            className="closeModalBtn"
            onClick={onHandleCloseModal}
          >
            x
          </Button>
          {/* <IconButton className='modalCloseBtn' onClick={onHandleCloseModal}><CloseIcon /></IconButton> */}
          {/* Loader */}
          {loaderFlag && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
          {/* For Status Change Modal
                    {modalType === "statusChange" && (
                        <>
                            <h2>
                                Are you sure you want to delete?
                            </h2>
                            <Button onClick={() => handleConfirmDelete(true)}>
                                Yes
                            </Button>
                            <Button onClick={() => handleConfirmDelete(false)}>
                                No
                            </Button>
                        </>
                    )} */}
          {/* For Delete Modal */}
          {
            <>
              <h2>
                Are you sure you want to delete these record(s)? This can't be
                undone
              </h2>
              <div className="modalSUBbtn_wrapper">
                <Button
                  type="button"
                  onClick={() => handleConfirmDelete(true)}
                  className="small modalSUBbtn"
                  disabled={loaderFlag}
                >
                  Yes
                </Button>
                <Button
                  type="button"
                  onClick={() => handleConfirmDelete(false)}
                  className="small modalSUBbtn"
                  disabled={loaderFlag}
                >
                  No
                </Button>
              </div>
              {/* <Button onClick={() => handleConfirmDelete(true)}>
                Yes
              </Button>
              <Button onClick={() => handleConfirmDelete(false)}>
                No
              </Button> */}
            </>
          }
        </Box>
      </Modal>
      <ModalBox
        open={openActionModal}
        close={closeModal}
        data={viewActionModal.current}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snakbaropen}
        onClose={handleClose}
        key="bottomcenter"
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {snackBarMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EventListing;
