import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useForm } from "react-hook-form";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { Box, Button, LinearProgress } from "@mui/material";
import "./Login.css";
import store from "../../../../store";
import { calllogin, getipInfo, setLogedinUserInfo } from "./Login_reducer";
import { useDispatch } from "react-redux";
import { useCookies, Cookies } from "react-cookie";

store.dispatch(getipInfo());

let storedata = {};
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loginMessage, setloginMessage] = useState(null);
  const [isLoggedIn, setisLoggedIn] = useState(false);

  const [userInfo, setuserInfoCookie] = useCookies(["userInfo"]);
  const [userGoogleCalendarCookie, setuserGoogleCalendarCookie] = useCookies([
    "google_calendar_connect_time",
  ]);
  const [userConnectedEmailCookie, setuserConnectedEmailCookie] = useCookies([
    "connected_gmail",
  ]);
  const [secret, setUserSecretCookie] = useCookies(["secret"]);
  const [token, setUserTokenCookie] = useCookies(["token"]);
  const [lastLoginTime, setLastLoginTimeCookie] = useCookies(["lastLoginTime"]);

  managenav(
    storedata,
    setuserInfoCookie,
    setUserSecretCookie,
    setUserTokenCookie,
    setuserGoogleCalendarCookie,
    setuserConnectedEmailCookie,
    navigate
  );

  store.subscribe(() => {
    const storedata = store.getState();
    // console.log("storedata>>>>>>>>>>>>>>>>>>>>>", storedata);
    setloginMessage(storedata.loginUserKey.errorMessage);
    setLoading(storedata.loginUserKey.loading);
    setisLoggedIn(storedata.loginUserKey.isLoggedIn);


    managenav(
      storedata,
      setuserInfoCookie,
      setUserSecretCookie,
      setUserTokenCookie,
      setLastLoginTimeCookie,
      setuserGoogleCalendarCookie,
      setuserConnectedEmailCookie,
      navigate
    );

  });

  useEffect(() => {
    if (isLoggedIn) {
      setLogedinUserInfo();
    }
  }, [isLoggedIn]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("Logged User data==>", data);
    dispatch(calllogin(data));
  };

  return (
    <>
      {/* {/ Meta Service Start Here /} */}
      {/* <MetaTags>
        <title>Login</title>
        <meta id="meta-description" name="description" content="One with Dawn for BioEnergetics, Michael for PECE, Integration meeting for PECE and another integration meeting for BioEnergetics." />
        <meta id="og-title" property="og:title" content="Login" />
        <meta
          id="og-image"
          property="og:image"
          content="path/to/og_image.jpg"
        />
      </MetaTags> */}
      {/* {/ Meta Service End Here /} */}

      <div className="mainContentWrapper login-form">

        {/* /////////////////////////////// LOADER ///////////////////////// */}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress className="layoutTopProgressBar" />
          </Box>
        )}

        <div className="loginFormdiv">
          {/* Login Form Start Here */}
          <div className="loginFormTopBlock">
            {/* Logo Section */}
            <h1 className="logoText">Welcome To</h1>
            <h3 className="logoText">Calendar Management App</h3>
            {/* Welcome Text Section */}
          </div>
          <div className="loginFormBlock">
            {/* Form Section Starts */}

            <h1 className="logoFormText">Login</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="countDiv">
                <div className="countDiv__column--col12 loginFormInputBox">
                  <label>Email</label>
                  <input
                    className="loginFormTextInput"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="error">Invalid email address</p>
                  )}
                </div>

                <div className="countDiv__column--col12 loginFormInputBox">
                  <label>Password</label>
                  <input
                    type="password"
                    className="loginFormTextInput"
                    {...register("password", {
                      required: true,
                      // pattern: {
                      //   value: /(?=.*\d)(?=.*[!@#$%&*])(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                      // }
                    })}
                  />
                  {errors.password && (
                    <p className="error">Password is required!</p>
                  )}
                  {loginMessage && <p className="success">{loginMessage}</p>}

                  {/* this code will use at the time of signup */}
                  {/* {validation_result !== true && (
                  <p className="error">{validation_result} </p>
                )} */}
                </div>
                <Link to="forgot-password" className="linkText">
                  Forgot Password?
                </Link>
              </div>

              {/* /////////////////////////////// LOADER ///////////////////////// */}

              {loading && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
              <div className="buttonLists">
                <Button
                  className="loginButton"
                  type="submit"
                  disabled={loading}
                >
                  Submit
                </Button>
              </div>
            </form>
            {/* Form Section Ends */}
          </div>
          {/* Login Form Ends Here */}
        </div>
      </div>
    </>
  );
};

function managenav(
  tempstore,
  setuserInfoCookie,
  setUserSecretCookie,
  setUserTokenCookie,
  setLastLoginTimeCookie,
  setuserGoogleCalendarCookie,
  setuserConnectedEmailCookie,
  navigate
) {
  console.log("tempstore++", tempstore);

  if (
    tempstore.loginUserKey?.userInfo !== undefined &&
    tempstore.loginUserKey?.userInfo?._id !== null &&
    tempstore.loginUserKey?.userInfo?._id !== undefined
  ) {
    console.log("tempstore", tempstore);
    setuserInfoCookie(
      "userInfo",
      JSON.stringify(tempstore.loginUserKey.userInfo),
      { path: "/" }
    );

    setuserGoogleCalendarCookie(
      "google_calendar_connect_time",
      JSON.stringify(tempstore.loginUserKey.google_calendar_connect_time),
      { path: "/" }
    );

    setuserConnectedEmailCookie(
      "connected_gmail",
      JSON.stringify(tempstore.loginUserKey.connected_gmail),
      { path: "/" }
    );

    setUserSecretCookie("secret", tempstore.loginUserKey.secret, { path: "/" });

    setUserTokenCookie("token", tempstore.loginUserKey.token, { path: "/" });

    setLastLoginTimeCookie(
      "lastLoginTime",
      tempstore.loginUserKey.last_login_time,
      { path: "/" }
    );
    if (window.location.pathname == '/') {
      navigate("/calendar-management-available-slots", { replace: true }); // navite to another url
    }
  }
}

export default Login;
